import React, { useState } from "react";
import Layout from "../components/pagestructure/Layout"
import Container from "../components/pagestructure/Container"
import Type from "../components/typography/Type"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Input from "../components/pitchreadinessform/Input"
import Select from "../components/pitchreadinessform/Select"
import TextArea from "../components/pitchreadinessform/TextArea"
import Hidden from "../components/pitchreadinessform/Hidden"
import Checkbox from "../components/pitchreadinessform/Checkbox"
import RadioButtonGroup from "../components/pitchreadinessform/RadioButtonGroup"
import Button from "../components/pitchreadinessform/Button"
import ProgressBar from "../components/pitchreadinessform/ProgressBar"
import FormBlock from "../components/pitchreadinessform/FormBlock"
import HeatmapCard from "../components/pitchreadinessform/HeatmapCard"
import Required from "../components/forms/Required"



import { useStaticQuery, graphql } from "gatsby";


 const formSchema = Yup.object().shape({
  FirstName: Yup.string().required("First name is required"), 
  LastName: Yup.string().required("Last name is required"), 
 Email: Yup.string().email("This doesn't look like a valid email").required("Email is required"), 
});







export const ReadinessRating = (values) => {
    
	

	
	
 const [setOutcome, setOutcomeState] = useState(false);	
 const [setOutcomeValues, setOutcomeValuesState] = useState(false);	
 
const [setProblemScore, setProblemScoreState] = useState(false);
const [setSolutionScore, setSolutionScoreState] = useState(false);
const [setProductScore, setProductScoreState] = useState(false);
const [setNowScore, setNowScoreState] = useState(false);
const [setMarketSizeScore, setMarketSizeScoreState] = useState(false);
const [setCompetitorsScore, setCompetitorsScoreState] = useState(false);
const [setBusinessModelScore, setBusinessModelScoreState] = useState(false);
const [setGTMScore, setGTMScoreState] = useState(false);
const [setFinancialsScore, setFinancialsState] = useState(false);
const [setTeamScore, setTeamScoreState] = useState(false);
const [setTractionScore, setTractionScoreState] = useState(false);
const [setFundingScore, setFundingScoreState] = useState(false);
const [setCredibleScore, setCredibleScoreState] = useState(false);
const [setCompellingScore, setCompellingScoreState] = useState(false);
const [setClearScore, setClearScoreState] = useState(false);

	
 // Axios server state handling
  const [serverState, setServerState] = useState();
  const handleServerResponse = (ok, msg) => {
	setServerState({ok, msg});
  };
  

  
  const handleOnSubmit = async (values, actions) => {
	  
	  const url = process.env.GATSBY_READINESS_URL;

	
	axios({
	  method: "POST",
	  url: url,
	  data: values,
	})
	  .then(response => {
		actions.setSubmitting(false);
		actions.resetForm();
		handleServerResponse(true, "Thanks!");
		setOutcomeValuesState(values);
		
		
		setProblemScoreState (parseInt((parseInt(!values.ProblemWho ? '0' : '1')+parseInt(!values.ProblemClear ? '0' : '1')+parseInt(!values.ProblemMagnitude ? '0' : '1')+parseInt(!values.ProblemPlanet ? '0' : '1'))/4*100));		
		
		setSolutionScoreState (parseInt((parseInt(!values.SolutionApproach ? '0' : '1')+parseInt(!values.SolutionAlternatives ? '0' : '1')+parseInt(!values.SolutionVision ? '0' : '1')+parseInt(!values.SolutionInertia ? '0' : '1'))/4*100));
		
		setProductScoreState (parseInt((parseInt(!values.ProductClear ? '0' : '1')+parseInt(!values.ProductSuperior ? '0' : '1')+parseInt(!values.ProductBenefits ? '0' : '1')+parseInt(!values.ProductRoadmap ? '0' : '1'))/4*100));
		
		setNowScoreState (parseInt((parseInt(!values.NowTrends ? '0' : '1')+parseInt(!values.NowTiming ? '0' : '1')+parseInt(!values.NowPlanet ? '0' : '1'))/3*100,0));
		
		setMarketSizeScoreState (parseInt((parseInt(!values.MarketModel ? '0' : '1')+parseInt(!values.MarketComposition ? '0' : '1')+parseInt(!values.MarketGrowth ? '0' : '1')+parseInt(!values.MarketCompelling ? '0' : '1'))/4*100));
		
		setCompetitorsScoreState (parseInt((parseInt(!values.CompetitorsComparison ? '0' : '1')+parseInt(!values.CompetitorsAdvantage ? '0' : '1')+parseInt(!values.CompetitorsMoat ? '0' : '1')+parseInt(!values.CompetitorsIdentified ? '0' : '1'))/4*100));
		
		setBusinessModelScoreState (parseInt((parseInt(!values.BusinessModelDeliver ? '0' : '1')+parseInt(!values.BusinessModelMonetise ? '0' : '1')+parseInt(!values.BusinessModelRevenue ? '0' : '1')+parseInt(!values.BusinessModelAcquisition ? '0' : '1')+parseInt(!values.BusinessModelImpact ? '0' : '1'))/5*100,0));
				
		setGTMScoreState (parseInt((parseInt(!values.GTMMarketing ? '0' : '1')+parseInt(!values.GTMSales ? '0' : '1')+parseInt(!values.GTMDistribution ? '0' : '1')+parseInt(!values.GTMGrowth ? '0' : '1')+parseInt(!values.GTMCOS ? '0' : '1'))/5*100,0));
		
		setFinancialsState (parseInt((parseInt(!values.FinanceToday ? '0' : '1')+parseInt(!values.FinancePandL ? '0' : '1')+parseInt(!values.FinanceMilestones ? '0' : '1')+parseInt(!values.FinancePlan ? '0' : '1'))/4*100,0));
		
		setTeamScoreState (parseInt((parseInt(!values.TeamComposition ? '0' : '1')+parseInt(!values.TeamAdvisors ? '0' : '1')+parseInt(!values.TeamUnique ? '0' : '1')+parseInt(!values.TeamSuperpowers ? '0' : '1')+parseInt(!values.TeamCommitment ? '0' : '1'))/5*100,0));
		
		setTractionScoreState (parseInt((parseInt(!values.TractionProof ? '0' : '1')+parseInt(!values.TractionLearnings ? '0' : '1')+parseInt(!values.TractionRepeatable ? '0' : '1')+parseInt(!values.TractionBarriers ? '0' : '1'))/4*100,0));
		
		setFundingScoreState (parseInt((parseInt(!values.FundingAsk ? '0' : '1')+parseInt(!values.FundingPriorities ? '0' : '1')+parseInt(!values.FundingResults ? '0' : '1'))/3*100,0));
		
		setCredibleScoreState (parseInt(parseInt(values.Credible)/3*100));
				
		setCompellingScoreState (parseInt(parseInt(values.Compelling)/3*100));
				
		setClearScoreState (parseInt(parseInt(values.Clear)/3*100));
				
		setOutcomeState(true);
		
	  })
	  .catch(error => {
		actions.setSubmitting(false);
		handleServerResponse(false, error.response.data.error);
	  });
  };
    


switch (setOutcome) {
	
	
	case true:
	window.scrollTo(0, 0);
	return (
		
		
		<Layout>
		<div className="bg-blue pt-16 pb-48">

		<Container>
		
		<div className="flex flex-col lg:flex-row space-x-0 space-y-12 lg:space-y-0 lg:space-x-24 mb-16">
			<div className="w-full lg:w-1/2">
				<Type type="h1" text="Your Readiness Rating"/>
				<Type type="para2" text="Your Readiness Rating is a heatmap of the most common pages in a pitch, scored based on best practice for each page."/>
				<Type type="para2" text="Use the heatmap to focus on critical areas to improve your pitch, and book a Story Workshop whenever you want a little extra help."/>
			</div>
			<div className="w-full lg:w-1/2">
				<div className="flex items-center justify-center">
				<div className="bg-tan rounded-xl px-20 py-8 font-bold text-5xl">
				{parseInt((
					parseInt(!setOutcomeValues.ProblemWho ? '0' : '1')+
					parseInt(!setOutcomeValues.ProblemClear ? '0' : '1')+
					parseInt(!setOutcomeValues.ProblemMagnitude ? '0' : '1')+
					parseInt(!setOutcomeValues.ProblemPlanet ? '0' : '1')+
					parseInt(!setOutcomeValues.SolutionVision ? '0' : '1')+
					parseInt(!setOutcomeValues.SolutionApproach ? '0' : '1')+
					parseInt(!setOutcomeValues.SolutionAlternatives ? '0' : '1')+
					parseInt(!setOutcomeValues.SolutionInertia ? '0' : '1')+
					parseInt(!setOutcomeValues.ProductClear ? '0' : '1')+
					parseInt(!setOutcomeValues.ProductSuperior ? '0' : '1')+
					parseInt(!setOutcomeValues.ProductBenefits ? '0' : '1')+
					parseInt(!setOutcomeValues.ProductRoadmap ? '0' : '1')+
					parseInt(!setOutcomeValues.NowTrends ? '0' : '1')+
					parseInt(!setOutcomeValues.NowTiming ? '0' : '1')+
					parseInt(!setOutcomeValues.NowPlanet ? '0' : '1')+
					parseInt(!setOutcomeValues.MarketModel ? '0' : '1')+
					parseInt(!setOutcomeValues.MarketComposition ? '0' : '1')+
					parseInt(!setOutcomeValues.MarketGrowth ? '0' : '1')+
					parseInt(!setOutcomeValues.MarketCompelling ? '0' : '1')+
					parseInt(!setOutcomeValues.CompetitorsComparison ? '0' : '1')+
					parseInt(!setOutcomeValues.CompetitorsAdvantage ? '0' : '1')+
					parseInt(!setOutcomeValues.CompetitorsMoat ? '0' : '1')+
					parseInt(!setOutcomeValues.CompetitorsIdentified ? '0' : '1')+
					parseInt(!setOutcomeValues.BusinessModelDeliver ? '0' : '1')+
					parseInt(!setOutcomeValues.BusinessModelMonetise ? '0' : '1')+
					parseInt(!setOutcomeValues.BusinessModelRevenue ? '0' : '1')+
					parseInt(!setOutcomeValues.BusinessModelAcquisition ? '0' : '1')+
					parseInt(!setOutcomeValues.BusinessModelImpact ? '0' : '1')+
					parseInt(!setOutcomeValues.GTMMarketing ? '0' : '1')+
					parseInt(!setOutcomeValues.GTMSales ? '0' : '1')+
					parseInt(!setOutcomeValues.GTMDistribution ? '0' : '1')+
					parseInt(!setOutcomeValues.GTMGrowth ? '0' : '1')+
					parseInt(!setOutcomeValues.GTMCOS ? '0' : '1')+
					parseInt(!setOutcomeValues.FinanceToday ? '0' : '1')+
					parseInt(!setOutcomeValues.FinancePandL ? '0' : '1')+
					parseInt(!setOutcomeValues.FinanceMilestones ? '0' : '1')+
					parseInt(!setOutcomeValues.FinancePlan ? '0' : '1')+
					parseInt(!setOutcomeValues.TeamComposition ? '0' : '1')+
					parseInt(!setOutcomeValues.TeamAdvisors ? '0' : '1')+
					parseInt(!setOutcomeValues.TeamUnique ? '0' : '1')+
					parseInt(!setOutcomeValues.TeamSuperpowers ? '0' : '1')+
					parseInt(!setOutcomeValues.TeamCommitment ? '0' : '1')+
					parseInt(!setOutcomeValues.TractionProof ? '0' : '1')+
					parseInt(!setOutcomeValues.TractionLearnings ? '0' : '1')+
					parseInt(!setOutcomeValues.TractionRepeatable ? '0' : '1')+
					parseInt(!setOutcomeValues.TractionBarriers ? '0' : '1')+
					parseInt(!setOutcomeValues.FundingAsk ? '0' : '1')+
					parseInt(!setOutcomeValues.FundingPriorities ? '0' : '1')+
					parseInt(!setOutcomeValues.FundingResults ? '0' : '1')+
					parseInt(!setOutcomeValues.Credible ? '0' : '1')+
					parseInt(!setOutcomeValues.Clear ? '0' : '1')+
					parseInt(!setOutcomeValues.Compelling ? '0' : '1')
				)/58*100,0)}%
				</div>
				</div>
			</div>
		</div>
		
		
		
		<div className="mb-8"><Type type="h3" text="Content"></Type></div>
			<div className="grid grid-cols-3 lg:grid-cols-6 gap-8 mb-16">
			
				
				<HeatmapCard title="Problem" score={setProblemScore}/>
				<HeatmapCard title="Solution" score={setSolutionScore}/>
				<HeatmapCard title="Product" score={setProductScore}/>
				<HeatmapCard title="Why Now?" score={setNowScore}/>
				<HeatmapCard title="Market Size" score={setSolutionScore}/>
				<HeatmapCard title="Competitors" score={setMarketSizeScore}/>
				<HeatmapCard title="Business Model" score={setCompetitorsScore}/>
				<HeatmapCard title="Go-To-Market" score={setBusinessModelScore}/>
				<HeatmapCard title="Financials" score={setGTMScore}/>
				<HeatmapCard title="Team" score={setFinancialsScore}/>
				<HeatmapCard title="Traction" score={setTeamScore}/>
				<HeatmapCard title="Funding" score={setTractionScore}/>
							
			</div>
			<div className="mb-8"><Type type="h3" text="Presentation"></Type></div>
			<div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-8">
			
			
					
				<HeatmapCard title="Credible" score={setCredibleScore}/>
				<HeatmapCard title="Compelling" score={setCompellingScore}/>
				<HeatmapCard title="Clear" score={setClearScore}/>
				
				
				
			</div>
			
		
		
			
			
	
		   </Container>
		   
		   
		   </div>
		   
		   <Container>
		   <div className="-mt-20 bg-tan p-12 grid grid-cols-1 lg:grid-cols-4 gap-8">
			 <div className="border-t-2 border-black pt-4">
			 <Type type="h6" text="1. Pitch Readiness"/>
			 <Type type="para3"	text="Every investor is different, but there are common patterns that investors will expect you to be able to address confidently."/>		 
			 </div>
		     <div className="border-t-2 border-black/50 pt-4 text-black/50">
			  	 
			  <Type type="h6" text="2. Story Workshop"/>
			  <Type type="para3"	text="People connect with stories and investors are no different."/>
			  </div>
			 <div className="border-t-2 border-black/50 pt-4 text-black/50">
			 <Type type="h6" text="3. Design Refresh	"/>
			 <Type type="para3"	text="The best pitch deck supports your story-telling, without getting in the way."/>
			  		 
			  </div>
			  <div className="border-t-2 border-black/50 pt-4 text-black/50">
			  <Type type="h6" text="4. Pitch Practice"/>
			  <Type type="para3"	text="How you share your ideas, passion and competence with investors plays a big part in their deciding to share in your vision."/>
			   		 
			   </div>
			 
		   </div>
		   
		   
		   
		   <div>
		   
		 
		 
		 {/*  Feature 2 - Pitch Story Workshop  */}
			 
		   <div className="flex flex-col lg:flex-row-reverse pt-20 lg:pt-32">
			 <div className="w-full lg:w-1/2 lg:pl-28">
			   <img
			   className="w-12 mb-10"
			   src="https://ik.imagekit.io/pathventures/pitchmark/Pitchmark-StoryWorkshop_zk5EW91bt.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1667451675788"
			   alt="Story Workshop"
			   />
			   <Type type="h4" text="Story Workshop"/>
			   <p className="text-sm mb-5">People connect with stories and investors are no different. Facts and figures are necessary but not sufficient for a winning pitch.</p>
			   <p className="text-sm mb-5">We help you shape your story into a strong narrative that builds belief in your venture.</p>
			   <p className="text-sm">You’ll come away with a refreshed pitch deck that brings your facts and figures to life for investors.</p>
			   <div className="mt-16 flex flex-row space-x-8">
			   
			   	<Button title="Book in Australia" url="https://calendly.com/pathventures/pitchmark_story_workshop"/>
			   	<Button title="Book in Europe" url=" https://calendly.com/pitchmark/workshop"/>
			  
			   
			   </div>
			   <div className="mt-8">
			   <h4 className="font-bold text-sm">Pitchmark Story Workshop</h4>
			   <p className="font-light text-lg line-through">$199</p>
			   <p className="font-bold text-lg">$99 <span className="font-light pl-4">Half-price introductory offer</span></p>
			   </div>
	
			 </div>
		   
			 <div className="w-full lg:w-1/2 text-left lg:text-left mt-4  lg:mt-0">
			   <img
				 className="w-80 rounded-md  invisible lg:visible h-0 lg:h-auto mx-auto"
				 src="https://ik.imagekit.io/pathventures/pitchmark/New/Pitchmark-protect_WEibjBvqGq.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1667453669536"
				 alt="Hands in a circle"
				 />
			 </div>
		   </div>
		   
		   
		   {/*  Feature 3 - Design Refresh  */}
			 
		   <div className="flex flex-col lg:flex-row pt-20 lg:pt-32">
			 <div className="w-full lg:w-1/2 lg:pr-28">
			   <img
			   className="w-12 mb-10"
			   src="https://ik.imagekit.io/pathventures/pitchmark/Pitchmark-DesignRefresh_A_jzqo_w9.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1667451675803"
			   alt="Design Refresh"
			   />
			   <Type type="h4" text="Design Refresh"/>
			   <p className="text-sm mb-5">The best pitch deck supports your story-telling, without getting in the way. There’s a perfect window between showing you understand design and trying too hard.</p>
			   <p className="text-sm mb-5">We help you create a dynamic and elegant pitch deck that tells your story without distracting from it.</p>
		
			 </div>
		   
			 <div className="w-full lg:w-1/2 text-left lg:text-right mt-4  lg:mt-0">
			   <img
				 className="w-96 mx-auto rounded-md  invisible lg:visible h-0 lg:h-auto"
				 src="https://ik.imagekit.io/pathventures/images/collaborating?ik-sdk-version=javascript-1.4.3&updatedAt=1656734289270"
				 alt="Designing together"
				 />
			 </div>
		   </div>
		   
		   
		   {/*  Feature 4 - Pitch Practice  */}
			 
		   <div className="flex flex-col lg:flex-row-reverse pt-20 lg:pt-32 mb-36">
			 <div className="w-full lg:w-1/2 lg:pl-28">
			   <img
			   className="w-12 mb-10"
			   src="https://ik.imagekit.io/pathventures/pitchmark/Pitchmark-PitchPractice_gpZ_CbPF93.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1667451675807"
			   alt="Pitch Practice"
			   />
			   <Type type="h4" text="Pitch Practice"/>
			   <p className="text-sm mb-5">It’s a truism that people invest in other people.</p>
			   <p className="text-sm mb-5">How you share your ideas, passion and competence with investors plays a big part in their deciding to share in your vision.</p>
			   <p className="text-sm mb-5">Practice makes perfect and we’ll help you hone your presentation skills through practice and critique, peer presentations and finally a video presentation that you can easily share with investors.</p>
			   <p className="text-sm mb-5">You’ll come away with a deep sense of confidence in your story and your story-tellling abilities, as well as a powerful video to add to your fund-raising bag of tricks. </p>

			 </div>
		   
			 <div className="w-full lg:w-1/2 text-left lg:text-left mt-4 lg:mt-0">
			   <img
				 className="w-80 rounded-md  invisible lg:visible h-0 lg:h-auto mx-auto"
				 src="https://ik.imagekit.io/pathventures/pitchmark/New/Pitchmark-Present_-raiG-OPM.svg"
				 alt="Presenting"
				 />
			 </div>
		   </div>          
		 
		 
		 
		 
		   
		   </div>
		   
		   
		   </Container>
		   
		
			</Layout>
		
	);	
	default:

return (
	

	<Layout>
	<div className="bg-blue pt-32 pb-10">
		<Container>
		
<div>
		
		   <div className="w-full sm:w-2/3 lg:w-1/2">
		  <Type type="h1" text="Is your pitch ready?"/>
		  <Type type="h2" text="Our pitch readiness checker helps you compare your pitch against the contents of a typical seed round pitch deck."/>
		  <Type type="h2" text="Reflect on what's included in your pitch deck and then answer the following questions. We'll provide you with a readiness rating for your pitch."/>
		</div>
			  
			  
			  
				  
			  
			  <>
			  
			<Formik
			  initialValues={{
				ProblemWho: false,
				ProblemClear: false,
				ProblemMagnitude: false,
				ProblemPlanet: false,
				SolutionVision: false,
				SolutionApproach: false,
				SolutionAlternatives: false,
				SolutionInertia: false,
				ProductClear: false,
				ProductSuperior: false,
				ProductBenefits: false,
				ProductRoadmap: false,
				NowTrends: false,
				NowTiming: false,
				NowPlanet: false,
				MarketModel: false,
				MarketComposition: false,
				MarketGrowth: false,
				MarketCompelling: false,
				CompetitorsComparison: false,
				CompetitorsAdvantage: false,
				CompetitorsMoat: false,
				CompetitorsIdentified: false,
				BusinessModelDeliver: false,
				BusinessModelMonetise: false,
				BusinessModelRevenue: false,
				BusinessModelAcquisition: false,
				BusinessModelImpact: false,
				GTMMarketing: false,
				GTMSales: false,
				GTMDistribution: false,
				GTMGrowth: false,
				GTMCOS: false,
				FinanceToday: false,
				FinancePandL: false,
				FinanceMilestones: false,
				FinancePlan		: false,
					
				TeamComposition: false,
				TeamAdvisors: false,
				TeamUnique: false,
				TeamSuperpowers: false,
				TeamCommitment: false,
				TractionProof: false,
				TractionLearnings: false,
				TractionRepeatable: false,
				TractionBarriers: false,
						
				FundingAsk: false,
				FundingPriorities: false,
				FundingResults: false,
				
				Compelling: '1',
				Clear: '1',
				Credible: '1',
				
				Organisation: '',
				Email: '',
				FirstName: '',
				LastName: '',
				Phone: '',
									
			  }}
			  onSubmit={handleOnSubmit}
			  validationSchema={formSchema}
			>
			  {({ values,
				 errors,
				 touched,
				 handleChange,
				 handleBlur,
				 handleSubmit,
				 isSubmitting 
			   }) => (
				
				<>
		
				
				
				<Form id="pitchReadiness" noValidate >
				
				<ProgressBar current="Opportunity"/>
								
				{/* Problem */}
			
				
			
				 <FormBlock>
				   <div className="flex sm:flex-row flex-col">
					 <div className="w-full sm:w-1/3 mr-0 sm:mr-10">
						<img
							 className="h-40"
							 src="https://ik.imagekit.io/pathventures/pitchmark/Problem.svg"
							 alt="Problem"
							 />
					 </div>
					 <div className="w-full sm:w-2/3">
					   <div className="text-green h-full flex items-center justify-center">
						 <div>
						   <div className="text-3xl mb-5 mt-5 sm:mt-0">Problem</div>
						   <Type type="para2" text="Describe the problem and its impact on people in the real world. Explain why current solutions aren't working." />
						 </div>
					   </div>
					 </div>
				   </div>
				   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
					<Field name="ProblemClear"  id="ProblemClear"  fieldLabel="Have you explained the problem in a way that is easily understood without any context?" component={Checkbox} value="1"/>   
				    <Field name="ProblemWho"  id="ProblemWho"  fieldLabel="Have you made it clear who has the problem and how it’s affecting their lives?" component={Checkbox} value="1"/>
				    <Field name="ProblemPlanet"  id="ProblemPlanet"  fieldLabel="Have you captured how the problem is impacting the planet?" component={Checkbox} value="1"/>
				    <Field name="ProblemMagnitude"  id="ProblemMagnitude"  fieldLabel="Have you quantified the magnitude of the problem?" component={Checkbox} value="1"/>
				   
				  
				   
				   
				   </div>
				   <div>
				   
				   </div>
				 </FormBlock>
				
	
				
		{/* Solution */}
		

	
	
	
	 <FormBlock>
	   <div className="flex sm:flex-row flex-col">
		 <div className="w-full sm:w-1/3 mr-0 sm:mr-10">
			<img
				 className="h-40"
				 src="https://ik.imagekit.io/pathventures/pitchmark/Solution.svg"
				 alt="Solution"
				 />
		 </div>
		 <div className="w-full sm:w-2/3">
		   <div className="text-green h-full flex items-center justify-center">
			 <div>
			   <div className="text-3xl mb-5 mt-5 sm:mt-0">Solution</div>
			   <Type type="para2" text="Share the big picture of how your solution works and why it's better than any other approach." />
			 </div>
		   </div>
		 </div>
	   </div>
	   <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
		<Field name="SolutionVision"  id="SolutionVision"  fieldLabel="Have you laid out a clear vision for your business? (a higher-order, outward-facing, long-term, positive future that your business will create)" component={Checkbox} value="1"/>   
		<Field name="SolutionApproach"  id="SolutionApproach"  fieldLabel="Have you described your unique approach to solving the problem?" component={Checkbox} value="1"/>
		<Field name="SolutionAlternatives"  id="SolutionAlternatives"  fieldLabel="Have you identified the alternatives available to your customers and the problems within these?" component={Checkbox} value="1"/>
		<Field name="SolutionInertia"  id="SolutionInertia"  fieldLabel="Have you shown how you will get customers to switch to your solution?" component={Checkbox} value="1"/>
	   
	  
	   
	   </div>
	   <div>
	   
	   </div>
	 </FormBlock>
	
	
	
	
	
	

	 {/* Product */}
	
	
<FormBlock>
	<div className="flex sm:flex-row flex-col">
		<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
			<img
			className="h-40"
			src="https://ik.imagekit.io/pathventures/pitchmark/Product.svg"
			alt="Product"
			/>
		</div>
		<div className="w-full sm:w-2/3">
			<div className="text-green h-full flex items-center justify-center">
				<div>
					<div className="text-3xl mb-5 mt-5 sm:mt-0">Product</div>
					<Type type="para2" text="Bring your product to life and show how it builds a bridge between the problem and the solution." />
				</div>
			</div>
		</div>
	</div>
	<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
		<Field name="ProductClear"  id="ProductClear"  fieldLabel="Have you described your product in a way that is easily understood without any context?" component={Checkbox} value="1"/>   
		<Field name="ProductSuperior"  id="ProductSuperior"  fieldLabel="Have you defined how your product is better than existing solutions?" component={Checkbox} value="1"/>
		<Field name="ProductBenefits"  id="ProductBenefits"  fieldLabel="Have you captured the benefits of the key features of your product?" component={Checkbox} value="1"/>
		<Field name="ProductRoadmap"  id="ProductRoadmap"  fieldLabel="Have you laid out a roadmap that incrementally delivers your vision?" component={Checkbox} value="1"/>
	</div>
</FormBlock>


{/* Why now? */}

<FormBlock>
	<div className="flex sm:flex-row flex-col">
		<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
			<img
			className="h-40"
			src="https://ik.imagekit.io/pathventures/pitchmark/WhyNow.svg"
			alt="Product"
			/>
		</div>
		<div className="w-full sm:w-2/3">
			<div className="text-green h-full flex items-center justify-center">
				<div>
					<div className="text-3xl mb-5 mt-5 sm:mt-0">Why now?</div>
					<Type type="para2" text="Help your audience understand the unique factors that make now the perfect moment for this solution." />
				</div>
			</div>
		</div>
	</div>
	<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
		<Field name="NowTrends"  id="NowTrends"  fieldLabel="Have you noted the trends and tailwinds that are changing the market where you operate?" component={Checkbox} value="1"/>   
		<Field name="NowTiming"  id="NowTiming"  fieldLabel="Have you captured why there is an opportunity in this market right now?" component={Checkbox} value="1"/>
		<Field name="NowPlanet"  id="NowPlanet"  fieldLabel="Have you shown a good reason why your product is needed for the planet right now?" component={Checkbox} value="1"/>
	</div>
</FormBlock>
	
	
	<ProgressBar current="Market"/>
							
	{/* MArket Size */}
	
	<FormBlock>
		<div className="flex sm:flex-row flex-col">
			<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
				<img
				className="h-40"
				src="https://ik.imagekit.io/pathventures/pitchmark/WhyNow.svg"
				alt="Market Size"
				/>
			</div>
			<div className="w-full sm:w-2/3">
				<div className="text-green h-full flex items-center justify-center">
					<div>
						<div className="text-3xl mb-5 mt-5 sm:mt-0">Market Size</div>
						<Type type="para2" text="Outline the product's potential based on the total size of the market, your addressable portion and the customers you expect to be able to reach." />
					</div>
				</div>
			</div>
		</div>
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
			<Field name="MarketModel"  id="MarketModel"  fieldLabel="Have you explained your revenue model and pricing structure?" component={Checkbox} value="1"/>   
			<Field name="MarketComposition"  id="MarketComposition"  fieldLabel="Have you defined the size and composition of your market (TAM, SAM, SOM)?" component={Checkbox} value="1"/>
			<Field name="MarketGrowth"  id="MarketGrowth"  fieldLabel="Have you noted how you will grow your SOM and SAM?" component={Checkbox} value="1"/>
			<Field name="MarketCompelling"  id="MarketCompelling"  fieldLabel="Have you captured what makes this market exciting?" component={Checkbox} value="1"/>

		</div>
	</FormBlock>



{/* Competitors */}
				
			<FormBlock>
				<div className="flex sm:flex-row flex-col">
					<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
						<img
						className="h-40"
						src="https://ik.imagekit.io/pathventures/pitchmark/Competitors.svg"
						alt="Competitors"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<div className="text-green h-full flex items-center justify-center">
							<div>
								<div className="text-3xl mb-5 mt-5 sm:mt-0">Competitors</div>
								<Type type="para2" text="Demonstrate that you have considered how to address your competitors strengths, including inertia." />
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
					
					<Field name="CompetitorsIdentified"  id="CompetitorsIdentified"  fieldLabel="Have you identified your competitors?" component={Checkbox} value="1"/>

					<Field name="CompetitorsComparison"  id="CompetitorsComparison"  fieldLabel="Have you defined how you compare to your competitors?" component={Checkbox} value="1"/>   
					<Field name="CompetitorsAdvantage"  id="CompetitorsAdvantage"  fieldLabel="Have you identified what’s unique about your product that gives you a competitive advantage that cannot be easily copied?" component={Checkbox} value="1"/>
					<Field name="CompetitorsMoat"  id="CompetitorsMoat"  fieldLabel="Have you noted your competitors’ market share and how you will capture market share and defend it from competitors?" component={Checkbox} value="1"/>
			
				</div>
			</FormBlock>
			
<ProgressBar current="Business"/>
							

{/* Business Model */}
				
			<FormBlock>
				<div className="flex sm:flex-row flex-col">
					<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
						<img
						className="h-40"
						src="https://ik.imagekit.io/pathventures/pitchmark/BusinessModel.svg"
						alt="Business Model"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<div className="text-green h-full flex items-center justify-center">
							<div>
								<div className="text-3xl mb-5 mt-5 sm:mt-0">Business Model</div>
								<Type type="para2" text="Show how your business will generate revenue. Demonstrate credibility by considering best and worst models." />
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
					
					<Field name="BusinessModelDeliver"  id="BusinessModelDeliver"  fieldLabel="Have you shown how you manufacture your product (or deliver your service)?" component={Checkbox} value="1"/>
			
					<Field name="BusinessModelMonetise"  id="BusinessModelMonetise"  fieldLabel="Have you included how you monetise your product (or service)?" component={Checkbox} value="1"/>   
					<Field name="BusinessModelRevenue"  id="BusinessModelRevenue"  fieldLabel="Have you explained how you make money?" component={Checkbox} value="1"/>
					<Field name="BusinessModelAcquisition"  id="BusinessModelAcquisition"  fieldLabel="Have you noted how you acquire customers?" component={Checkbox} value="1"/>
					<Field name="BusinessModelImpact"  id="BusinessModelImpact"  fieldLabel="Have you explained how you create a positive impact?" component={Checkbox} value="1"/>
						
				</div>
			</FormBlock>

		
		{/* Go-To-Market */}
			
		<FormBlock>
			<div className="flex sm:flex-row flex-col">
				<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
					<img
					className="h-40"
					src="https://ik.imagekit.io/pathventures/pitchmark/GoToMarket.svg"
					alt="Go-To-Market"
					/>
				</div>
				<div className="w-full sm:w-2/3">
					<div className="text-green h-full flex items-center justify-center">
						<div>
							<div className="text-3xl mb-5 mt-5 sm:mt-0">Go-To-Market</div>
							<Type type="para2" text="Describe how you plan to grow your customer base from zero. Show that you know how to get there from here." />
						</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
				
				<Field name="GTMSales"  id="GTMSales"  fieldLabel="Have you shown how customers will decide to buy your product (Sales)?" component={Checkbox} value="1"/>
		
				<Field name="GTMDistribution"  id="GTMDistribution"  fieldLabel="Have you explained how customers will get to use your product (Distribution)?" component={Checkbox} value="1"/>   
				<Field name="GTMCOS"  id="GTMCOS"  fieldLabel="Have you captured the costs to achieve the volumes of business you are projecting in your SOM?" component={Checkbox} value="1"/>
				<Field name="GTMMarketing"  id="GTMMarketing"  fieldLabel="Have you defined how customers will learn about your product (Marketing)?" component={Checkbox} value="1"/>
				<Field name="GTMGrowth"  id="GTMGrowth"  fieldLabel="Have you noted major events that will drive growth (events, product releases, partnerships, new markets, etc.)?" component={Checkbox} value="1"/>
					
			</div>
		</FormBlock>
		
				
		{/* Financials */}
			
		<FormBlock>
			<div className="flex sm:flex-row flex-col">
				<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
					<img
					className="h-40"
					src="https://ik.imagekit.io/pathventures/pitchmark/Financials.svg"
					alt="Financials"
					/>
				</div>
				<div className="w-full sm:w-2/3">
					<div className="text-green h-full flex items-center justify-center">
						<div>
							<div className="text-3xl mb-5 mt-5 sm:mt-0">Financials</div>
							<Type type="para2" text="Estimate the financial growth you expect to achieve over the next 3-5 years. Be clear and credible about what you aim to achieve and what it will cost to get there." />
						</div>
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
				
				<Field name="FinanceToday"  id="FinanceToday"  fieldLabel="Have you captured where you are today ($ raised, $ revenue, FTEs, $ burn, runway)?" component={Checkbox} value="1"/>
		
				<Field name="FinancePandL"  id="FinancePandL"  fieldLabel="Have you created 5-year projections of your profit and loss?" component={Checkbox} value="1"/>   
	
				<Field name="FinanceMilestones"  id="FinanceMilestones"  fieldLabel="Have you highlighted key milestones of your business (break-even, GTM activity, etc)" component={Checkbox} value="1"/>
				<Field name="FinancePlan"  id="FinancePlan"  fieldLabel="Have you broken down how you plan to use the funds you’re raising?" component={Checkbox} value="1"/>
					
			</div>
		</FormBlock>		
																															 
				<ProgressBar current="Strengths"/>
					
					
						
			{/* Team */}
				
			<FormBlock>
				<div className="flex sm:flex-row flex-col">
					<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
						<img
						className="h-40"
						src="https://ik.imagekit.io/pathventures/pitchmark/Team.svg"
						alt="Team"
						/>
					</div>
					<div className="w-full sm:w-2/3">
						<div className="text-green h-full flex items-center justify-center">
							<div>
								<div className="text-3xl mb-5 mt-5 sm:mt-0">Team</div>
								<Type type="para2" text="Establish that your team that can successfully execute your vision. Talk about the skills and experience that they bring, what they've done in other companies and the relationships that will help guide you to success." />
							</div>
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
					
					<Field name="TeamComposition"  id="TeamComposition"  fieldLabel="Does your team composition reflect the solution you have identified?" component={Checkbox} value="1"/>
			
					<Field name="TeamAdvisors"  id="TeamAdvisors"  fieldLabel="Have you noted advisors that unlock huge growth opportunities?" component={Checkbox} value="1"/>   
			
					<Field name="TeamUnique"  id="TeamUnique"  fieldLabel="Have you captured the unique and relevant skills and experience of your team?" component={Checkbox} value="1"/>
					<Field name="TeamSuperpowers"  id="TeamSuperpowers"  fieldLabel="Have you summarised your team’s super powers?" component={Checkbox} value="1"/>
					<Field name="TeamCommitment"  id="TeamCommitment"  fieldLabel="Is it clear why your team cares about the problem, why you’re competent at solving it and how you are committed to doing so?" component={Checkbox} value="1"/>
						
				</div>
			</FormBlock>			
					
				{/* Traction */}
					
				<FormBlock>
					<div className="flex sm:flex-row flex-col">
						<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
							<img
							className="h-40"
							src="https://ik.imagekit.io/pathventures/pitchmark/Traction.svg"
							alt="Traction"
							/>
						</div>
						<div className="w-full sm:w-2/3">
							<div className="text-green h-full flex items-center justify-center">
								<div>
									<div className="text-3xl mb-5 mt-5 sm:mt-0">Traction</div>
									<Type type="para2" text="Show that you already have momentum that funding will help you accelerate. Share key metrics or milestones that you've already achieved." />
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
						
						<Field name="TractionProof"  id="TractionProof"  fieldLabel="Have you shown proof that your business is working?" component={Checkbox} value="1"/>
				
						<Field name="TractionLearnings"  id="TractionLearnings"  fieldLabel="Have you captured how your learnings led to your current business model?" component={Checkbox} value="1"/>   
				
						<Field name="TractionRepeatable"  id="TractionRepeatable"  fieldLabel="Have you explained how you’ve been able to repeat these successes?" component={Checkbox} value="1"/>
						<Field name="TractionBarriers"  id="TractionBarriers"  fieldLabel="Have you shown evidence that you have succeeded at over-coming difficult obstacles? Technical breakthroughs, key client wins, etc" component={Checkbox} value="1"/>
						
					</div>
				</FormBlock>	
				
				<ProgressBar current="Ask"/>
									
									
				
				{/* Funding */}
					
				<FormBlock>
					<div className="flex sm:flex-row flex-col">
						<div className="w-full sm:w-1/3 mr-0 sm:mr-10">
							<img
							className="h-40"
							src="https://ik.imagekit.io/pathventures/pitchmark/TheAsk.svg"
							alt="Funding"
							/>
						</div>
						<div className="w-full sm:w-2/3">
							<div className="text-green h-full flex items-center justify-center">
								<div>
									<div className="text-3xl mb-5 mt-5 sm:mt-0">Funding</div>
									<Type type="para2" text="Now that your audience is excited about the opportunity, reassured about the risks and clear how you plan to accelerate, you are ready to discuss the funding you are looking for." />
								</div>
							</div>
						</div>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:mt-20 ">
						
						<Field name="FundingAsk"  id="FundingAsk"  fieldLabel="Have you captured how much you need to achieve your vision and build the business?" component={Checkbox} value="1"/>
				
						<Field name="FundingPriorities"  id="FundingPriorities"  fieldLabel="Have you identified the top 3-4 things that you will do with the funding?" component={Checkbox} value="1"/>   
				
						<Field name="FundingResults"  id="FundingResults"  fieldLabel="Have you explained the results that your business will get from spending that money?" component={Checkbox} value="1"/>
					
			
					
					</div>
				</FormBlock>	
				
			<ProgressBar current="Presentation"/>
								
								
				<FormBlock>
				
					<div className="mb-24">
					  <img
					  className="h-14"
					  src="https://ik.imagekit.io/pathventures/pitchmark/Credible.svg"
					  alt="Credible"
					  />
					  <div  id="credibleGroup" className="text-3xl my-10 text-green">Are your assumptions and data credible? <Required/></div>
						<div role="group" aria-labelledby="credibleGroup"  className="flex flex-col lg:flex-row lg:space-x-8 space-x-0 lg:space-y-0 space-y-8">
						  <label>
							<Field type="radio" name="Credible" value="1" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
							<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Not at all</p>
						  </label>
						  <label>
							<Field type="radio" name="Credible" value="2" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
							<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Some</p>
						  </label>
						  <label>
							  <Field type="radio" name="Credible" value="3" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink"/>
							  <p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Very</p>
							</label>
							
							{errors.Credible && touched.Credible ? (<div className="mt-4 text-base font-light bg-red/10 py-2 px-4 rounded-lg w-fit">{errors.Credible}</div>) : null}

						</div>
					</div>
				
				
				<div className="mb-24">
				  <img
				  className="h-14"
				  src="https://ik.imagekit.io/pathventures/pitchmark/Compelling.svg"
				  alt="Compelling"
				  />
				  <div  id="compellingGroup" className="text-3xl my-10 text-green">Is your story compelling? <Required/></div>
					<div role="group" aria-labelledby="compellingGroup"  className="flex flex-col lg:flex-row lg:space-x-8 space-x-0 lg:space-y-0 space-y-8">
					  <label>
						<Field type="radio" name="Compelling" value="1" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
						<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Not at all</p>
					  </label>
					  <label>
						<Field type="radio" name="Compelling" value="2" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
						<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Some</p>
					  </label>
					  <label>
						  <Field type="radio" name="Compelling" value="3" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink"/>
						  <p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Very</p>
						</label>
						{errors.Compelling && touched.Compelling ? (<div className="mt-4 text-base font-light bg-red/10 py-2 px-4 rounded-lg w-fit">{errors.Compelling}</div>) : null}

					</div>
				</div>
				
				<div className="">
				  <img
				  className="h-14"
				  src="https://ik.imagekit.io/pathventures/pitchmark/Clear.svg"
				  alt="Clear"
				  />
				  <div  id="clearGroup" className="text-3xl my-10 text-green">Is your pitch well-presented and clear? <Required/></div>
					<div role="group" aria-labelledby="clearGroup" required className="flex flex-col lg:flex-row lg:space-x-8 space-x-0 lg:space-y-0 space-y-8">
					  <label>
						<Field type="radio" name="Clear" value="1" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
						<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Not at all</p>
					  </label>
					  <label>
						<Field type="radio" name="Clear" value="2" required className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink" />
						<p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Some</p>
					  </label>
					  <label>
						  <Field type="radio" name="Clear" value="3"  className="w-8 h-8 p-2 border-pink border border-black/50 font-light text-pink focus:ring-pink"/>
						  <p className="font-light text-lg text-gray-700 inline ml-4 mr-8">Very</p>
						</label>
						{errors.Clear && touched.Clear ? (<div className="mt-4 text-base font-light bg-red/10 py-2 px-4 rounded-lg w-fit">{errors.Clear}</div>) : null}

					</div>
				</div>
				
	
				</FormBlock>
				
				
				
				{/* Progress bar  */}
				<div className="mt-16 flex flex-row">
				  <div className="mx-auto pt-4 text-3xl text-left">
					<img
					className="h-14 mb-10"
					src="https://ik.imagekit.io/pathventures/pitchmark/ResultsBlack.svg"
					alt="Results"
					/>
					<div className="text-3xl my-10 text-black">Where should we send your results?</div>
				  </div>
				</div>
				
				
				{/* Contact details*/}
				
				<FormBlock>
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
					  <div className="">
						<Field name="Org" id="Org" fieldLabel="Organisation" component={Input} />
						   
					  </div>
					  <div></div>
					  <div className="">
						<Field name="FirstName" id="FirstName" fieldLabel="First name" component={Input} />

					  </div>
					  <div className="">      
						<Field name="LastName" id="LastName" fieldLabel="Last name" component={Input} />

					  </div>
					  <div className="">

						<Field name="Email" id="Email" fieldLabel="Email" component={Input} />

					  </div>
					  <div className="">
						<Field name="Phone" id="Phone" fieldLabel="Phone" component={Input} />
					  </div>
					</div>
				</FormBlock>
				
				
				
					
				  
				  <button type="submit" className="mt-16 bg-green rounded-lg py-4 px-8 text-white border text-lg border-green hover:bg-white hover:text-green font-bold" disabled={isSubmitting}>
					Submit
				  </button>
				  
				  <div>
				 {serverState && (
				   <div className="mt-8">
				   <div className={!serverState.ok ? "errorMsg" : ""}>
					 {serverState.msg}
				   </div>
				   </div>
				 )}
				  </div>
				</Form>
				</>
			  )}
			</Formik>
			
		  </>
		
		
		
		
		
		 </div>
		
			

	
	
	
	   </Container>
	</div>
		</Layout>
);
};
};	
export default ReadinessRating;

export const Head = () => <title>Pitchmark Readiness Rating</title>

