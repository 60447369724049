import * as React from "react";
import Type from "../../components/typography/Type"



const Checkbox = ({ 
  field, 
  form: { touched, errors, values },
  fieldLabel,
}) => {



const fieldName = field.name


return (
  
<div>
<label className="flex flex-row font-light" htmlFor={fieldName}>
    <input
      type="checkbox"
      className="mr-8 checkbox w-8 h-8 rounded-lg p-2 border-black/50 border font-light text-pink focus:ring-pink"
      {...field}
    />
    {fieldLabel} 
</label>
 {errors[fieldName] && touched[fieldName] ? (<div className="mt-4 text-base font-light bg-red/10 py-2 px-4 rounded-lg w-fit">{errors[fieldName]}</div>) : null}
</div>

   
 );
};  
export default Checkbox ;