
import React from "react"
import Type from "../../components/typography/Type"


const HeatmapCard = (props) => {	
	
const score = props.score; 
const title = props.title; 
const heatmap = "quarter"; 
	

if(score == 0) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="w-full bg-pink rounded-xl py-6 text-center">
			<h3 className="font-bold text-2xl">{score}%</h3>
		</div>
	</div>
);
}
if(score > 0 && score < 25) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="bg-pink/50 rounded-xl py-6 px-8 text-center">
			<h3 className="font-bold text-2xl">{score}%</h3>
		</div>
	</div>
);
}
if(score >= 25 && score < 50) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="bg-tan rounded-xl py-6 px-8 text-center">
			<h3 className="font-bold text-2xl">{score}%</h3>
		</div>
	</div>
);
}
if(score >= 50 && score < 75) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="bg-green/25 rounded-xl py-6 px-8 text-center">
			<h3 className="font-bold text-2xl">{score}%</h3>
		</div>
	</div>
);
}
if(score >= 75 && score < 100) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="bg-green/75 rounded-xl py-6 px-8 text-center">
			<h3 className="font-bold text-2xl">{score}%</h3>
		</div>
	</div>
);
}
if(score == 100) {
return (
	<div>
		<h6 className="uppercase text-sm font-light mb-2">{title}</h6>
		<div className="bg-green rounded-xl py-6 px-8 text-center">
			<h3 className="font-bold text-2xl text-white">{score}%</h3>
		</div>
	</div>
);
}	

	
	
	
};

export default HeatmapCard;
